import Vue from 'vue';
const state = Vue.observable({ // this is the magic
    modal: false
});

const getters = {
    getModal: () => state.modal
}
const mutations = {
    setModal: (val) => state.modal = val
}
export {
    state,
    getters,
    mutations
}

