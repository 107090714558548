export const getRouteForCurrentLanguage = (route) => {
    return route + '-' + getCurrentLanguage()
}

export const getCurrentUrlForLanguage = (lang) => {
    var pathname = window.location.pathname.replace(/\/ru(\/?)/, '/');
    if (lang !== 'en') {
        pathname = '/ru' + pathname;
    }

    var hostname = window.location.hostname;
    if (process.env.NODE_ENV !== 'production') {
        hostname = hostname + (window.location.port ? ':' + window.location.port : '');
    }

    return window.location.protocol + '//' + hostname + pathname + window.location.hash
}

export const getCurrentUrlForAnotherLanguage = (lang) => {
    var pathname = window.location.pathname.replace(/\/ru(\/?)/, '/');
    if (lang === 'en') {
        pathname = '/ru' + pathname;
    }

    var hostname = window.location.hostname;
    if (process.env.NODE_ENV !== 'production') {
        hostname = hostname + (window.location.port ? ':' + window.location.port : '');
    }

    return 'https://devqode.com' + pathname + window.location.hash
}

export const getAlternatesForLanguage = (lang) => {
    return [
        {'rel': 'alternate', 'hreflang': (lang === 'en' ? 'en-us' : 'ru-ru'), 'href': getCurrentUrlForAnotherLanguage(lang)},
    ];
}

export const getAlternatesForCurrentLanguage = () => {
    let lang = getCurrentLanguage()
    return [
        {'rel': 'alternate', 'hreflang': (lang === 'en' ? 'ru-ru' : 'en-us'), 'href': getCurrentUrlForAnotherLanguage(lang)},
    ];
}

export const getCanonicals = () => [{'rel': 'canonical', 'href': getCurrentUrl()}]

export const getCurrentLanguage = () => {
    return window.location.pathname.includes('/ru') ? 'ru' : 'en';
}

export const getCurrentUrl = () => {
    return 'https://devqode.com'  + location.pathname
}

export const trackEvent = (eventName) => {
    // console.log('Event: ' + eventName)
    window.fbq('track', eventName)
}

export default {
    getRouteForCurrentLanguage,
    getCurrentUrlForLanguage,
    getCurrentUrlForAnotherLanguage,
    getAlternatesForLanguage,
    getAlternatesForCurrentLanguage,
    getCurrentLanguage,
    getCanonicals,
    getCurrentUrl,
    trackEvent
}