import Vue from "vue"
import App from "./App.vue"
import vuetify from './plugins/vuetify'
import './assets/sass/_common.sass'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import Axios from 'axios'
import VeeValidate from 'vee-validate'
import VueScrollTo from 'vue-scrollto'
import router from './router'
// import VueAnalytics from 'vue-analytics'

Vue.use(VueScrollTo)
Axios.defaults.headers.common = {
    accept: 'application/json',
    'Accept-Language': i18n.locale,
    'content-type': 'application/json'
};

Vue.config.productionTip = false;
Vue.component('SvgIcon', () => import('./components/SvgIcon.vue'))
Vue.component('buttonCallPopup', () => import('./components/buttonCallPopup.vue'))
Vue.use(VueMeta)
Vue.use(VeeValidate)
// Vue.use(VueAnalytics, {
//     id: 'UA-161238243-1'
// });

var vm = new Vue({
    vuetify,
    i18n,
    router,
    render: h => h(App),
    mounted() {
        setTimeout(() => {
            document.dispatchEvent(new Event('render-event'));
        }, 5000);
    },
}).$mount("#app");
