import Vue from 'vue'
import VueRouter from 'vue-router'
import il8n from "../i18n";

Vue.use(VueRouter)

const routes = [
    {
        path: '/ru',
        component: () => import('@/views/appmain.vue'),
        children: [
            {
                path: '',
                name: 'main-ru',
                component: () => import('@/pages/home/homePage.vue')
            },
            {
                path: 'blockchain-development',
                name: 'blockchain-ru',
                component: () => import('@/pages/blockchain/blockchainPage.vue')
            },
            {
                path: 'exchange',
                name: 'exchange-ru',
                component: () => import('@/pages/exchange/exchangePage.vue')
            },
            {
                path: 'exchangers',
                name: 'launch-ru',
                component: () => import('@/pages/launch/launchPage.vue')
            },
            {
                path: 'wallets-and-apps',
                name: 'development-ru',
                component: () => import('@/pages/development/developmentPage.vue')
            },
            {
                path: 'smart-contracts',
                name: 'contracts-ru',
                component: () => import('@/pages/contracts/contractsPage.vue')
            },
            {
                path: 'custom-development',
                name: 'customdev-ru',
                component: () => import('@/pages/custom/customPage.vue')
            },
            {
                path: 'blog',
                name: 'blog-ru'
            },
        ],
    },
    {
        path: '/',
        name: 'main-en',
        component: () => import('@/pages/home/homePage.vue')
    },
    {
        path: '/blockchain-development',
        name: 'blockchain-en',
        component: () => import('@/pages/blockchain/blockchainPage.vue')
    },
    {
        path: '/exchange',
        name: 'exchange-en',
        component: () => import('@/pages/exchange/exchangePage.vue')
    },
    {
        path: '/exchangers',
        name: 'launch-en',
        component: () => import('@/pages/launch/launchPage.vue')
    },
    {
        path: '/wallets-and-apps',
        name: 'development-en',
        component: () => import('@/pages/development/developmentPage.vue')
    },
    {
        path: '/smart-contracts',
        name: 'contracts-en',
        component: () => import('@/pages/contracts/contractsPage.vue')
    },
    {
        path: '/custom-development',
        name: 'customdev-en',
        component: () => import('@/pages/custom/customPage.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/pages/privacy/privacyPage.vue')
    },
    {
       path: '/blog',
       name: 'blog-en'
    },
    {
        path: '*',
        redirect() {
            let lang = process.env.VUE_APP_I18N_LOCALE
            if (lang === 'ru') {
                return 'ru'
            } else {
                return '/';
            }
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    let language = to.path.includes('/ru') ? 'ru' : 'en';

    il8n.locale = language
    next();
})
  
export default router
  