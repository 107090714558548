<template>
  <v-app>
    <modal></modal>
    <header-section></header-section>
    <router-view></router-view>
    <footer-section></footer-section>
  </v-app>
  
</template>

<script>
import navigationHeader from '@/pages/asections/navigationHeader.vue';
import footerElement from '@/pages/apartials/footerElement';
import modal from '@/elements/modal.vue';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Devqode - Outstanding custom development',
      keywords: this.$t('meta_keywords'),
      description: this.$t('meta_description')
    }
  },
  components: {
      'modal': modal,
      'header-section': navigationHeader,
      'footer-section': footerElement
  },
  data: () => ({
  }),
};
</script>
