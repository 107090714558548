<template>
        <v-dialog
                v-model="showModal"
                max-width="510px"
        >
            <v-card>
                <v-form @submit.prevent="createRequest">
                    <div class="v-card__close" @click="setModal(false)">
                        <svg-icon name="close"/>
                    </div>
                    <div class="v-card__inner">
                        <div class="v-card__header">
                            <div class="v-card__messengers">
                                <a href="https://t.me/mark_miller7" class="telegram" target="_blank">
                                  <img src="@/assets/icons/telegram-icon@2x.png" alt=""/>
                                </a>
                                <a href="https://wa.me/+380683113282" target="_blank">
                                  <img src="@/assets/icons/whatsapp-icon@2x.png" alt=""/>
                                </a>
<!--                                <img src="@/assets/img/logo-black.svg" alt="">-->
                            </div>
                        </div>
                        <div class="v-card__body">
                            <div class="v-card__col">
                                <div class="v-card__sub">
                                    {{$t("modal.email")}}
                                </div>
                                <div class="v-card__field">
                                    <v-text-field
                                            v-model="email"
                                            data-vv-name="email"
                                            v-validate="`${!username ? 'required' : ''}|email`"
                                            :error-messages="errors.collect('email')"
                                            :placeholder="$t('modal.email')"
                                            class="input-main"
                                            type="email"
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                            <div class="v-card__col">
                                <div class="v-card__sub">
                                    {{$t("modal.connect")}}
<!--                                    <sup>*</sup>-->
                                </div>
                                <div class="v-card__field">
                                    <v-text-field
                                            v-model="username"
                                            v-validate="`${!email ? 'required' : ''}`"
                                            :error-messages="errors.collect('username')"
                                            :placeholder="$t('modal.username')"
                                            data-vv-name="username"
                                            required

                                            class="input-main"
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                            <div class="v-card__col">
                                <div class="v-card__sub">
                                    {{$t("modal.help")}}
<!--                                    <sup>*</sup>-->
                                </div>
                                <div class="v-card__field">
                                    <v-textarea
                                        v-model="description"
                                        v-validate="'required'"
                                        :error-messages="errors.collect('message')"
                                        data-vv-name="message"
                                        :placeholder="$t('modal.write')"
                                        rows="5"

                                        class="input-main"
                                    >
                                    </v-textarea>
                                </div>
                            </div>
                        </div>
                        <div class="v-card__action">
                            <div class="v-card__btn">
                                <button type="submit" class="btn btn-main">
                                    {{$t("modal.submit")}}
                                </button>
                            </div>
                        </div>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
</template>

<script>
    import { getters, mutations} from '@/store.js';
    import Axios from 'axios'
    export default {
        name: 'popup',
        data() {
            return {
                count: 3000,
                description: '',
                email: '',
                username: '',
//                modal: false
            }
        },
        watch: {
            showModal(prev, next) {
                if(prev === false) {
                  this.resetInputs()
                } else {
                  window.fbq('track', 'SubmitApplication');
              }
            }
        },
        computed: {
            ...getters,
            showModal: {
                get() {
                    return getters.getModal()
                },
                set(val) {
                    return mutations.setModal(val)
                }
//                set(  ){return this.$emit("input", !this.modal)}
            },

        },
        methods: {
            ...mutations,
//            changeModalState() {
//                this.modal = !this.modal
//            },

            resetInputs() {
                let vm = this;
                this.$nextTick()
                    .then(() => {
                        this.$validator.reset();
                        vm.email = vm.username = vm.description = '';
                    });
            },
            createRequest() {
                this.$validator.validateAll()
                    .then(res => {
                        if(res) {
                            this.apiCall()
                        }
                    })
            },
            async apiCall() {
                let {email, username, description} = this;
                let data = {email, username, description}
                try {
                   let url = 'https://formspree.io/mrglkned';
                   let response = await Axios.post(url, data);

                   gtag('event', 'sendForm', { 'event_category': 'form', 'event_action': 'submit', });
                   window.fbq('track', 'Lead')

                   this.resetInputs();
                   this.setModal(false);
                } catch(e) {
                }
            }
        },
    }
</script>

