<template>
    <div class="burger-menu_container" >
        <div class="burger-menu_list"  @click="closeMenu"> 
            <router-link class="burger-menu_list-item"
                v-for="(el, i) in routes"
                :key="i"
                tag="button"
                :to="{name: el}"
                :exact-active-class="`navlink-active--tablet`"
                
            >   
            <!-- @click="handleDropdown"  -->
                <div :class="`burger-menu_list-item_text${routeName===el ? '-bordered' : ''}`" >
                    <span class="item_routename">{{$t(`link.title-${i}`)}}</span>
                </div>
                <div v-if="routeName === el" class="horizontal-line"></div>
                <svg-icon v-if="routeName === el" name="burger-checkmark" />
            </router-link>
        </div>
        <div class="burger-menu_bg-images">
            <img class="burger-menu-background_network-1" :src="require(`@/assets/img/scenes/development-installation-network.svg`)" alt="">
            <img class="burger-menu-background_network-2" :src="require(`@/assets/img/scenes/development-installation-network.svg`)" alt="">
        </div>
        <div class="close-menu_button-holder">
            <div class="locale-button"><buttonCallPopup classList="btn-secondary btn-mobile" type="btnCall"></buttonCallPopup></div>
            <div class="close-menu_button-wrapper">
                <div class="close-menu_button" @click="closeMenu">
                    <svg-icon name="close-cross" />
                </div>
            </div>
            <div class="contact-button" ><switcher/></div>
        </div>
        <div class="burger-background" @click="closeMenu">

        </div>
    </div>
</template>

<script>
import switcher from '@/elements/switcher.vue'
import {getCurrentLanguage} from "../../helpers/locale-helper";

export default {
    props: {
        closeMenu: {
            type: Function,
            required: true
        }
    },
    data() {
      return {
        routes: this.getRoutes(),
      }
    },
    methods: {
      getRoutes() {
        let lang = getCurrentLanguage()

        return ['main-' + lang, 'exchange-' + lang, 'blockchain-' + lang, 'development-' + lang, 'launch-' + lang, 'contracts-' + lang, 'customdev-' + lang];
      },
      updateLocale() {
        this.routes = this.getRoutes()
      },
    },
    components: {
        switcher
    },
    computed: {
        routeName() {
            return this.$route.name
        }
    },
    created() {
      window.addEventListener("change-locale", this.updateLocale);
    },
}
</script>

<style lang="sass" scoped>
.btn-mobile
    width: 160px

.burger-background
    background: #0E1937 
    height: 1000px
    width: 100%
    opacity: 0.8
    position: absolute

.locale-button
    position: relative 
    display: none
    @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
        display: flex

.contact-button
    postion: relative
    display: none
    @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
        display: flex
        align-items: center 
        justify-content: center

.close-menu_button
    width: 48px 
    height: 48px 
    border: 1px solid rgba(255, 255, 255, 0.5)
    border-radius: 3px
    background: #222C48
    display: flex 
    align-items: center 
    justify-content: center
    position: absolute
    left: -24px
    bottom: -34px
    cursor: pointer
    z-index: 2
    @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
        display: none

    &-holder 
        display: flex
        justify-content: center
        align-items: center
        height: 24px
        position: relative
        border-bottom: 1px solid rgba(255, 255, 255, 0.5)
        @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
            height: 60px
            justify-content: space-between
            margin: 5px 23px 32px 19px
            border-bottom: none

    &-wrapper 
        width: 1px
        position: relative

.svg-icon--close-cross 
    width: 24px 
    height: 24px

.burger-menu
    &-background_network-1
        width: 50vw
        @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
            display: none
    &-background_network-2
        width: 50vw
        @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
            width: 100%
        transform: scaleX(-1)
    &_container 
        position: relative
    &_list
        width: 100%
        height: 100%
        display: flex 
        flex-direction: column
        align-items: flex-start
        justify-content: center
        &-item
            display: flex 
            align-items: center
            width: 100%
            height: 44px
            margin: 11px 0 10px 19px
            padding-right: 42px
            &_text
                display: block
                min-width: max-content
                @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)

                &-bordered  
                    min-width: max-content           
                    border-color: #4071F4
                    border-style: solid 
                    border-width: 0.5px 0.5px 0.5px 0
                    border-radius: 0 8px 8px 0 
                    @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
            &:focus 
                outline: 0 
        &-content 
            position: absolute
    &_bg-images
        position: absolute
        width: 100%
        display: flex 
        justify-content: space-between
        bottom: -40px
        z-index: -1
        opacity: 0.4
        @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
            width: 140%
            left: -100px

.item_routename
    display: block
    padding: 13px 8px 15px 0
    font-size: 16px 
    line-height: 14px
    text-align: left
    @media screen and (max-width: 270px), (min-device-width: 100px) and (max-device-width: 270px)
        font-size: 14px 
        line-height: 12px 

.horizontal-line
    width: 100%
    border-bottom: 1px solid #4071F4

.svg-icon--burger-checkmark
    width: 34px 
    height: 34px
    min-width: 34px
    max-width: 34px
</style>