<template>
      <!-- HEADER PLACE !-->
</template>
<script>
    import ClickOutside from 'vue-click-outside'
    import switcher from '@/elements/switcher.vue'
    import burderMenuList from '../apartials/burgerMenuList.vue'
    import {getCurrentLanguage, trackEvent} from "../../helpers/locale-helper";
    export default {
        components: {
            switcher,
            'tablet-list': burderMenuList
        },
        directives: {
            ClickOutside
        },
        data() {
          return {
              sticky: false,
              isDropdownOpened: false,
              windowWidth: window.innerWidth > 767,
              windowWidthMenuClose: window.innerWidth > 1264,
              routes: this.getRoutes(),
              list: [
                  {
                      'title': this.$t('cryptoSolutionsMenuTitle'),
                      sub: [
                          {
                            title: this.$t('blockchain'),
                            anchor: '#blockchain'
                          },
                          {
                              title: this.$t('advantages'),
                              anchor: '#advantages'
                          },
                          {
                              title: this.$t('exchangesTitle'),
                              anchor: '#exchanges'
                          },
                          {
                              title: this.$t('tradeTitle'),
                              anchor: '#trade'
                          },
                          {
                              title: this.$t('contractsTitle'),
                              anchor: '#contracts'
                          }
                      ]
                  },
                  {
                      'title': this.$t('exchangesMenuTitle'),
                      sub: [
                          {
                              title: this.$t('exchangesTitle'),
                              anchor: '#exchanges'
                          },
                          {
                              title: this.$t('tradeTitle'),
                              anchor: '#trade'
                          },
                      ]
                  },
                  {
                      'title': this.$t('developmentMenuTitle'),
                      sub: [
                          {
                             title: this.$t('developmentTitle'),
                             anchor: '#development'
                          },
                          {
                              title: this.$t('technology'),
                              anchor: '#technology'
                          }
                      ]
                  }
              ]
          }
        },
        methods: {
          trackEvent,
          getRoutes() {
            let lang = getCurrentLanguage()

            return ['main-' + lang, 'exchange-' + lang, 'blockchain-' + lang, 'development-' + lang, 'launch-' + lang, 'contracts-' + lang, 'customdev-' + lang, 'blog-' + lang];
          },
          updateLocale() {
             this.routes = this.getRoutes()
          },
            handleScroll() {
                if (window.pageYOffset < 0) {
                    return
                }

                if (window.pageYOffset) {
                    this.sticky = true
                } else {
                    this.sticky = false
                }
            },
            
            handleDropdown() {

                console.log('close')
                this.isDropdownOpened = !this.isDropdownOpened
            },

            handleCloseMenu() {
                if (this.windowWidthMenuClose)
                    this.isDropdownOpened = this.isDropdownOpened && false
                
                this.isDropdownOpened = this.isDropdownOpened && false
            },

            handleFocusout() {
                this.isDropdownOpened = false
            }
        },
        created() {
            if(this.windowWidth) {
                window.addEventListener("scroll", this.handleScroll);
            }

            window.addEventListener("resize", this.handleCloseMenu);
            window.addEventListener("change-locale", this.updateLocale);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
            window.removeEventListener("resize", this.handleCloseMenu)
        },
    }
</script>
<style lang="sass">
@import './navigationHeader.sass'

.amazin-line
    width: 20px 
    height: 2px
    background: #fff
    border-radius: 20%
    margin: 4px 0
    box-shadow: 0 1px 0 #537FF5

.tablet-dropdown

.header__right
    .contact-button
        @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
            display: none

.language-switcher
    @media screen and (max-width: 500px), (min-device-width: 100px) and (max-device-width: 500px)
        display: none
</style>
