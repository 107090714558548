<template>
    <div :class="active" class="select">
        <div v-if="Menu" v-click-outside="hide"></div>
        <div class="select__inner" :class="active" @click="menuActive()">
            <div class="select__title">{{lang}}</div>
            <div class="select__arrow"></div>
        </div>
        <transition name="fade">
            <div v-if="Menu" :class="`sub ${Menu}`">
                <div class="menu" v-for="(item, index) in list" :key="index" @click="selected(item.name)">
                    <span class="menu__title">{{item.name}}</span>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import ClickOutside from 'vue-click-outside'
    import { getCurrentUrlForLanguage, getCurrentLanguage } from '@/helpers/locale-helper'
    import il8n from '@/i18n.js'
    import Axios from 'axios'

    export default {
        data () {
            return {
                Menu: '',
                active: '',
                languages: [
                    {name: "EN", slug: 'en'},
                    {name: "RU", slug: 'ru'},
                ],
                lang: this.getCurrentLanguage(),
                show: false
            }
        },
        computed: {
            list() {
                const self = this
                return this.languages.filter(function(item) {
                    return item.name.toLowerCase() !== self.lang
                });
            },
            isActive() {
                return {
                    'is-active': this.show
                }
            }
        },
        methods: {
            getCurrentUrlForLanguage,
            getCurrentLanguage,
            saveLang(lang) {
                localStorage.lang = lang;
                il8n.locale = lang;
                Axios.defaults.headers.common['Accept-Language'] = lang

                this.changeURL(lang)
            },
            changeURL(lang) {
                let url = getCurrentUrlForLanguage(lang);

                history.pushState('change-lang', url, url);
                window.dispatchEvent(new Event('change-locale'));
            },
            selected(lang) {
                lang = lang.toLowerCase()
                this.lang = lang
                this.Menu = ''
                this.active = ''
                this.saveLang(lang)
            },
            menuActive() {
                if(!this.Menu) {
                    this.active = 'is-active'
                    this.Menu = 'Active'

                }else {
                    this.Menu = ''
                    this.active = ''
                }
            },

            toggle() {
                this.show = !this.show
            },
            hide() {
                this.Menu = this.active = ''
                this.show = false
            },
        },
        mounted () {
            // prevent click outside event with popupItem.
            this.popupItem = this.$el
        },
        directives: {
            ClickOutside
        }
    };
</script>

<style lang="sass" scoped>
.select
    cursor: pointer
    position: relative
    border-radius: 4px
    &__arrow
        width: 8px
        height: 8px
        border-top: 2px solid rgba(#fff, 0.5)
        border-right: 2px solid rgba(#fff, 0.5)
        position: absolute
        top: 43%
        right: 5px
        transform: translateY(-50%) rotate(135deg)
        transition: transform .3s ease, border-color .3s ease
    &__title
        text-transform: uppercase
        +reg
        font-size: 12px
        line-height: 16px
        color: #fff
        +tr(color .3s ease)
    &__inner
        cursor: pointer
        display: flex
        justify-content: space-between
        align-items: center
        padding: 4px
        border-radius: 4px
        position: relative
        width: 42px
        /*border: 1px solid transparent*/
        +tr(border-color .3s ease)
        &:hover
            border-color: $color--white
        &.is-active
            border-color: $color--white
            .select__arrow
                border-color: $color--white
                transform: rotate(225deg) rotateX(190deg)
            .select__title
                color: $color--white

.sub
    border-radius: 4px
    position: absolute
    z-index: 111
    padding: 12px 12px
    top: 30px
    right: 0
    opacity: 0
    width: 72px
    transition: all 0.2s ease
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    width: 100%
    background-color: $color--white
.menu
    width: 100%
    display: flex
    justify-content: space-between
    cursor: pointer
    &:not(:first-child)
        margin-top: 5px
    &__title
        font-size: 12px
        text-align: left
        +reg
        line-height: 16px
        color: $color--dark-blue
        width: 100%
        +tr(color .3s ease)
        &:hover
            color: $color--blue
    &__icon
        position: absolute
        right: 30px
        height: 20px
.Active
    opacity: 1
.fade-enter, .fade-leave-to
    opacity: 0
    +tr(opacity .3s ease)


</style>


